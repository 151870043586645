<template>
    <div class="p-5 text-center">
        <check-circle-icon size="4x" class="text-success mb-3"></check-circle-icon>
        <h2>Pagamento effettuato</h2>
        <p>Il pagamento è in processamento e tra pochissimo i tuoi crediti saranno disponibili</p>
        <a href="/" class="btn btn-primary">Vai alla home</a>
    </div>
</template>

<script>
import { Requests } from '@/api/requests';
import { CheckCircleIcon } from 'vue-feather-icons';
export default {
    components: {
        CheckCircleIcon
    },
}
</script>